<template>
    <div class="detail">
        <div class="con-top">
            <div class="con-top-l section-wrap p20">
                <div class="title">作品信息</div>
                <div class="info-item">
                    <div class="label">作品名称：</div>
                    <div class="value">
                        <edit-name :name="info.name" :id="info.id" @editName="nameChange"></edit-name>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品分类：</div>
                    <div class="value">
                        <div class="tag-manage">
                            <el-tag class="tag" size="small" :key="index" v-for="(tag, index) in dynamicTags" closable
                                :disable-transitions="false" @close="handleClose(tag)">
                                {{ tag.label }}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
                                size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <div class="tag-add" v-else @click="showInput">
                                <div class="btn-add"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品状态：</div>
                    <div class="value">
                        <el-tag class="status" size="small" type="success" v-if="info.status == 5">创作成功</el-tag>
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 1">创作中</el-tag>
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 2">合成中</el-tag>
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 3">排队中</el-tag>
                        <el-tag class="status" size="small" type="danger" v-if="info.status == 4">创作失败</el-tag>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">发音人：</div>
                    <div class="value">
                        <div class="flex_box flex_wrap">
                            <div class="voice mr10" v-for="(item, index) in info.tts_text" :key="index">{{ item }}</div>
                            <el-tag class="tag flex_box flex_center" size="small" type="success">本地音视频
                                <el-tooltip class="item" effect="dark" content="当前作品通过本地音视频配音" placement="top">
                                    <img class="wenhao" src="@/assets/images/creation/creationMng_(46).png" alt="">
                                </el-tooltip>
                            </el-tag>
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品时长：</div>
                    <div class="value">
                        <div class="txt">{{ info.video_duration }}秒</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品链接：</div>
                    <div class="value">
                        <div class="link_url">
                            <div class="txt">{{ info.video_url }}</div>
                            <div class="copy-btn cursor" @click="copyUrl(info.video_url)">复制</div>
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">宽高比：</div>
                    <div class="value">
                        <div class="txt" v-if="info.aspect_ratio == 1">16:9</div>
                        <div class="txt" v-if="info.aspect_ratio == 2">9:16</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">分辨率：</div>
                    <div class="value">
                        <div class="txt">{{ info.resolution }}</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">码率：</div>
                    <div class="value">
                        <div class="txt">{{ info.bit_rate }}M</div>
                    </div>
                </div>
            </div>
            <div class="con-top-r section-wrap p20">
                <div class="video-container">
                    <div v-if="info.icon_play" class="icon_play" @click="play(info)"></div>
                    <img v-if="info.icon_play" :src="info.video_img" alt="" class="creation-cover-img">
                    <video v-if="!info.icon_play" autoplay controls="controls" class="creation-cover"
                        :poster="info.video_img" :src="info.video_url"
                        controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                </div>
            </div>
        </div>
        <div class="con-bottom section-wrap mt30 p20 fixed-r">
            <el-button class="btnBgColor_blue" size="small" type="primary" plain v-if="info.is_draft == 1">返回编辑</el-button>
            <el-button class="btnBgColor_blue" size="small" type="primary"
                @click="downloadFile(info.video_url, info.name)">下载</el-button>
        </div>
    </div>
</template>
<script>
import EditName from '../editCreationName/index.vue'

export default {
    components: {
        EditName
    },
    props: {
        info: {
            type: Object,
            default: () => { }
        },
        cate_list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            title: '视频详情',
            inputVisible: false,
            inputValue: '',
        }
    },
    computed: {
        dynamicTags() {
            let arr = []
            let video_category = []
            if (this.info.video_category) {
                video_category = this.info.video_category.split(',')
                this.cate_list.forEach(item => {
                    video_category.forEach(i => {
                        if (i == item.value) {
                            arr.push(item)
                        }
                    })
                })
            }
            return arr
        }
    },
    created() {

    },
    methods: {
        // 删除分类
        handleClose(tag) {
            const data = {
                id: this.info.id,
                category_id: tag.value,
            }
            this.$creationApi.delVideoCategory(data).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshBtn')
                } else {
                    this.$errMsg(res.message)
                }
            })
            // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        // 添加分类
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                // this.dynamicTags.push(inputValue);
                const data = {
                    id: this.info.id,
                    name: inputValue,
                }
                this.$creationApi.addVideoCategory(data).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('refreshBtn')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        nameChange(val) {
            this.$creationApi.editVideoName({ name: val.name }, val.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshBtn')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 复制
        copyUrl(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 下载
        downloadFile(url, name) {
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const a = document.createElement("a");
                    const objectUrl = window.URL.createObjectURL(blob);
                    a.download = name;
                    a.href = objectUrl;
                    a.click();
                    window.URL.revokeObjectURL(objectUrl);
                    a.remove();
                })
        },
        // 播放
        play(item) {
            this.$set(item, "icon_play", false)
        },

    }
}
</script>
<style lang="scss" scoped>
@import './index.scss';

.detail {}
</style>