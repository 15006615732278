<template>
    <div class="edit-name">
        <span class="cursor name" v-if="!flag" @click="toDetail">{{ name }}</span>
        <span class="btn-edit cursor" v-if="!flag" @click="editNameFlag"><i class="el-icon-edit-outline"></i></span>
        <el-input ref="inputName" size="small" placeholder="请输入名称" v-if="flag" v-model="nameN" @blur="inptBlur"></el-input>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            flag: false,
            nameN: '',
        }
    },
    created() {
    },
    methods: {
        toDetail() {
            let obj = {
                id: this.id,
                name: this.nameN ? this.nameN : this.name
            }
            this.$emit('toDetail', obj)
        },
        editNameFlag() {
            this.flag = !this.flag
            if (this.flag) {
                this.$nextTick(() => {
                    this.$refs.inputName.focus()
                    this.nameN = ''
                })
            }
        },
        inptBlur() {
            this.editNameFlag()
            if (!this.nameN) {
                return
            }
            let obj = {
                id: this.id,
                name: this.nameN
            }
            this.$emit('editName', obj)
        }
    }
}
</script>
<style lang="scss" scoped>
.edit-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    height: 28px;

    .btn-edit {
        margin-left: 10px;
    }

    .cursor {
        cursor: pointer;
    }

    .name {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>